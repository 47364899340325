import {
  GUID,
  Optional,
  PropOptional,
  validateArrayOf,
  validateEnumValue,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";
import {
  ISOTimeString,
  UserId,
  validateQuat,
  validateVec3,
} from "@faro-lotv/ielement-types";
import { DataSetLocalPose, isDataSetLocalPose } from "./project-api-types";

/** Possible types of a capture tree entity */
export enum CaptureTreeEntityType {
  cluster = "Cluster",
  focusScan = "FocusScan",
  orbisScan = "OrbisScan",
  pCloudUploadScan = "PCloudUploadScan",
  elsScan = "ElsScan",
  root = "Root",
}

/** Possible types of a capture tree point cloud */
export enum CaptureTreePointCloudType {
  cpe = "Cpe",
  e57 = "E57",
  laz = "Laz",
  geoslam = "GeoSlam",
  pCloud = "PCloud",
  flsRaw = "FlsRaw",
  flsProcessed = "FlsProcessed",
  elsRaw = "ElsRaw",
  elsProcessed = "ElsProcessed",
}

export type CaptureTreePointCloudProperties = {
  externalId?: string;
  /** The id of the capture tree point cloud */
  id: GUID;
  /**  The type of the capture tree point cloud*/
  type: CaptureTreePointCloudType;
  /** The URI to the capture tree point cloud */
  uri: string | null;
  /**  The md5 hash of the point cloud file */
  md5Hash: string | null;
  /**  The file size of the capture tree point cloud */
  fileSize: number | null;
  /**  The file name of the capture tree point cloud */
  fileName: string | null;
};

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid CaptureTreePointCloudProperties
 */
export function isCaptureTreePointCloudProperties(
  data: unknown,
): data is CaptureTreePointCloudProperties {
  return (
    validateNotNullishObject<CaptureTreePointCloudProperties>(
      data,
      "CaptureTreePointCloudProperties",
    ) &&
    validatePrimitive(data, "id", "string") &&
    validatePrimitive(data, "externalId", "string", PropOptional) &&
    validatePrimitive(data, "uri", "string", PropOptional) &&
    validatePrimitive(data, "md5Hash", "string", PropOptional) &&
    validatePrimitive(data, "fileName", "string", PropOptional) &&
    validatePrimitive(data, "fileSize", "number", PropOptional) &&
    validateEnumValue(data.type, CaptureTreePointCloudType)
  );
}

export type CaptureTreeEntity = {
  /** The unique global identifier of the CaptureTreeEntity */
  id: GUID;
  /**  The id of the parent entity */
  parentId: GUID | null;
  /** The name of the entity */
  name: string;
  /**  The pose of the entity */
  pose: DataSetLocalPose;
  /** The type of the entity */
  type: CaptureTreeEntityType;
  /** The ID of the user who created the entity */
  createdBy: UserId;
  /** The date time when the entity was created */
  createdAt: ISOTimeString;
  /** The ID of the user who last patched the entity */
  lastPatchedBy: UserId;
  /** The date time when the entity was last patched */
  lastPatchedAt: ISOTimeString;
  /** An array of point cloud representations of this entity */
  pointClouds?: CaptureTreePointCloudProperties[];
};

/** The status of an object in a revision */
export enum RevisionStatus {
  initialized = "Initialized",
  added = "Added",
  modified = "Modified",
  deleted = "Deleted",
}

export type CaptureTreeEntityRevision = CaptureTreeEntity & {
  /** The status of the entity revision */
  status: RevisionStatus;
};

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid CaptureTreeEntity
 */
export function isCaptureTreeEntity(data: unknown): data is CaptureTreeEntity {
  return (
    validateNotNullishObject<CaptureTreeEntity>(data, "CaptureTreeEntity") &&
    validatePrimitive(data, "id", "string") &&
    validatePrimitive(data, "name", "string") &&
    validatePrimitive(data, "lastPatchedBy", "string") &&
    validatePrimitive(data, "createdAt", "string") &&
    validatePrimitive(data, "createdBy", "string") &&
    validatePrimitive(data, "lastPatchedAt", "string") &&
    validateEnumValue(data.type, CaptureTreeEntityType) &&
    isDataSetLocalPose(data.pose) &&
    validateArrayOf({
      object: data,
      prop: "pointClouds",
      elementGuard: isCaptureTreePointCloudProperties,
      optionality: PropOptional,
    })
  );
}

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid CaptureTreeEntity
 */
export function isCaptureTreeEntityRevision(
  data: unknown,
): data is CaptureTreeEntityRevision {
  return (
    validateNotNullishObject<CaptureTreeEntityRevision>(
      data,
      "CaptureTreeEntityRevision",
    ) &&
    validateEnumValue(data.status, RevisionStatus) &&
    isCaptureTreeEntity(data)
  );
}

/** The state of a registration revision */
export enum RegistrationState {
  started = "Started",
  cloudRegistrationStarted = "CloudRegistrationStarted",
  registered = "Registered",
  merged = "Merged",
  canceled = "Canceled",
}

/** The list of possible clients that create a registration revision */
export enum CaptureApiClient {
  stream = "Stream",
  scene = "Scene",
  registrationBackend = "RegistrationBackend",
}

export type RegistrationRevision = {
  /** The unique global identifier of the RegistrationRevision */
  id: GUID;
  /** The ID of the user who created the revision */
  createdBy: UserId;
  /** The date time when the revision was created */
  createdAt: UserId;
  /** The ID of the user who last modified the revision */
  modifiedBy: UserId;
  /** The date time when the revision was last modified */
  modifiedAt: UserId;
  /** The projectId of the registration revision */
  projectId: GUID;
  /** The state of the registration revision */
  state: RegistrationState;
  /** The client that created the registration revision */
  createdByClient: CaptureApiClient | null;
  /** The URI of the registration report */
  reportUri: string | null;
};

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid RegistrationRevision
 */
export function isRegistrationRevision(
  data: unknown,
): data is RegistrationRevision {
  return (
    validateNotNullishObject<RegistrationRevision>(
      data,
      "RegistrationRevision",
    ) &&
    validateEnumValue(data.state, RegistrationState) &&
    validatePrimitive(data, "id", "string") &&
    validatePrimitive(data, "projectId", "string") &&
    validatePrimitive(data, "modifiedAt", "string") &&
    validatePrimitive(data, "createdAt", "string") &&
    validatePrimitive(data, "createdBy", "string") &&
    validatePrimitive(data, "modifiedBy", "string")
  );
}

/** The type of a registration edge */
export enum RegistrationEdgeType {
  preReg = "PreReg",
  local = "Local",
}

export type RegistrationEdge = {
  /** The unique global identifier of the RegistrationEdge */
  id: GUID;
  /** The ID of the user who created the edge */
  createdBy: UserId;
  /** The date time when the edge was created */
  createdAt: UserId;
  /** The ID of the user who last patched the registration edge. */
  lastPatchedBy: UserId;
  /** The date time when the registration edge was last patched. */
  lastPatchedAt: ISOTimeString;
  /** The type of registration performed on this edge. */
  type: RegistrationEdgeType;
  /** The ID of the source element for this registration. */
  sourceId: GUID;
  /** The ID of the target element for this registration. */
  targetId: GUID;
  /** Additional data (the type is not enforced by the API currently) */
  data: unknown;
};

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid RegistrationEdge
 */
export function isRegistrationEdge(data: unknown): data is RegistrationEdge {
  return (
    validateNotNullishObject<RegistrationEdge>(data, "RegistrationEdge") &&
    validateEnumValue(data.type, RegistrationEdgeType) &&
    validatePrimitive(data, "id", "string") &&
    validatePrimitive(data, "createdAt", "string") &&
    validatePrimitive(data, "lastPatchedAt", "string") &&
    validatePrimitive(data, "lastPatchedBy", "string") &&
    validatePrimitive(data, "createdBy", "string") &&
    validatePrimitive(data, "sourceId", "string") &&
    validatePrimitive(data, "targetId", "string")
  );
}

export type RegistrationEdgeRevision = RegistrationEdge & {
  status: RevisionStatus;
};

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid RegistrationEdgeRevision
 */
export function isRegistrationEdgeRevision(
  data: unknown,
): data is RegistrationEdgeRevision {
  return (
    validateNotNullishObject<RegistrationEdgeRevision>(
      data,
      "RegistrationEdgeRevision",
    ) &&
    validateEnumValue(data.status, RevisionStatus) &&
    isRegistrationEdge(data)
  );
}

/** The entity types which correspond to a point cloud scan. */
export const revisionScanEntityTypes = [
  CaptureTreeEntityType.elsScan,
  CaptureTreeEntityType.focusScan,
  CaptureTreeEntityType.orbisScan,
  CaptureTreeEntityType.pCloudUploadScan,
] as const;

/** A revision entity corresponding to a point cloud scan. */
export type RevisionScanEntity = CaptureTreeEntityRevision & {
  type: (typeof revisionScanEntityTypes)[number];
};

/**
 * @param entity The revision entity to check.
 * @returns Whether the revision entity is a point cloud scan.
 */
export function isRevisionScanEntity(
  entity: CaptureTreeEntityRevision,
): entity is RevisionScanEntity {
  return Object.values<CaptureTreeEntityType>(revisionScanEntityTypes).includes(
    entity.type,
  );
}

export type UpdateRegistrationRevisionParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** The updated state of the registration */
  state: RegistrationState;

  /** The URI of the registration report */
  reportUri?: string;

  /** The project point cloud of this revision */
  projectPointCloud?: CaptureTreePointCloudProperties;
};

/** Params to set the pose of a new entity */
type CreateEntityPoseParam = Optional<DataSetLocalPose, "scale">;

/**
 * @param data to check
 * @returns true if it matches the CreateEntityPoseParam type
 */
export function isCreateEntityPoseParam(
  data: unknown,
): data is CreateEntityPoseParam {
  return (
    validateNotNullishObject(data, "CreateEntityPoseParam") &&
    validateOfType(data, "pos", validateVec3) &&
    validateOfType(data, "rot", validateQuat) &&
    validateOfType(data, "scale", validateVec3, PropOptional)
  );
}

/** Request body to create a root entity */
type CreateRootEntityRequestBody = {
  /** The pose of the entity */
  pose: CreateEntityPoseParam;
};

/**
 * @param data to check
 * @returns true if it matches the CreateRootEntityRequestBody type
 */
export function isCreateRootEntityRequestBody(
  data: unknown,
): data is CreateRootEntityRequestBody {
  return (
    validateNotNullishObject(data, "CreateRootEntityRequestBody") &&
    isCreateEntityPoseParam(data.pose)
  );
}

/** Params for the request to create a root entity */
export type CreateRootEntityParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** Request body */
  requestBody: CreateRootEntityRequestBody;
};

/** Params to create a cluster entity  */
type CreateClusterEntityParams = {
  /** The pose of the entity */
  pose: CreateEntityPoseParam;

  /** The id of the parent entity */
  parentId: GUID;

  /** The name of the entity */
  name: string;
};

/**
 * @param data to check
 * @returns true if it matches the CreateClusterEntityParams type
 */
export function isCreateClusterEntityParams(
  data: unknown,
): data is CreateClusterEntityParams {
  return (
    validateNotNullishObject(data, "CreateClusterEntityParams") &&
    isCreateEntityPoseParam(data.pose) &&
    validatePrimitive(data, "parentId", "string") &&
    validatePrimitive(data, "name", "string")
  );
}

/** Request body to create clusters entities: array of cluster entity params */
type CreateClusterEntitiesRequestBody = CreateClusterEntityParams[];

/**
 * @param data to check
 * @returns true if it matches the CreateClusterEntitiesRequestBody type
 */
export function isCreateClusterEntitiesRequestBody(
  data: unknown,
): data is CreateClusterEntitiesRequestBody {
  const obj = { requestBody: data };

  return validateArrayOf({
    object: obj,
    prop: "requestBody",
    elementGuard: isCreateClusterEntityParams,
  });
}

/** Params for the request to create cluster entities */
export type CreateClusterEntitiesParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** Request body */
  requestBody: CreateClusterEntitiesRequestBody;
};

/** Params to create a point cloud entity */
type PointCloudParams = Pick<CaptureTreePointCloudProperties, "type"> & {
  externalId: string;

  /** The URI to the capture tree point cloud */
  uri: string;

  /** The md5 hash of the point cloud file */
  md5Hash: string;

  /** The file size of the capture tree point cloud */
  fileSize: number;

  /** The file name of the capture tree point cloud */
  fileName: string;
};

/**
 * @param data to check
 * @returns true if it matches the PointCloudParams type
 */
export function isPointCloudParams(data: unknown): data is PointCloudParams {
  return (
    validateNotNullishObject(data, "PointCloudParams") &&
    validatePrimitive(data, "externalId", "string") &&
    validatePrimitive(data, "uri", "string") &&
    validatePrimitive(data, "md5Hash", "string") &&
    validatePrimitive(data, "fileName", "string") &&
    validatePrimitive(data, "fileSize", "number")
  );
}

/** Params to create a scan entity */
type CreateScanEntityParams = {
  /** The pose of the entity */
  pose: CreateEntityPoseParam;

  /** The id of the parent entity */
  parentId: GUID;

  /** The name of the entity */
  name: string;

  /** The type of the scan entity */
  type: CaptureTreeEntityType;

  /** An array of point cloud representations of this entity */
  pointClouds: PointCloudParams[];
};

/**
 * @param data to check
 * @returns true if it matches the CreateScanEntityParams type
 */
export function isCreateScanEntityParams(
  data: unknown,
): data is CreateScanEntityParams {
  return (
    validateNotNullishObject(data, "CreateScanEntityParams") &&
    isCreateEntityPoseParam(data.pose) &&
    validatePrimitive(data, "parentId", "string") &&
    validatePrimitive(data, "name", "string") &&
    validateEnumValue(data.type, CaptureTreeEntityType) &&
    validateArrayOf({
      object: data,
      prop: "pointClouds",
      elementGuard: isPointCloudParams,
    })
  );
}

/** Request body to create scan entities: array of scan entity params */
type CreateScanEntitiesRequestBody = CreateScanEntityParams[];

/**
 * @param data to check
 * @returns true if it matches the CreateScanEntitiesRequestBody type
 */
export function isCreateScanEntitiesRequestBody(
  data: unknown,
): data is CreateScanEntitiesRequestBody {
  const obj = { requestBody: data };

  return validateArrayOf({
    object: obj,
    prop: "requestBody",
    elementGuard: isCreateScanEntityParams,
  });
}

/** Params for the request to create scans entities */
export type CreateScanEntitiesParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** Request body */
  requestBody: CreateScanEntitiesRequestBody;
};
